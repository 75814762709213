
import { Row, Col, Image, Button, Card } from 'antd';
import { usePuestoFotoAspirante } from '../hooks/useObtenerPuestoFoto'
import { ModalInfo } from "../componentes/ModalInfo";
import { useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import funcionDeUnCAE from '../assets/img/funcion_de_un_CAE.png';
import funcionDeUnSE from '../assets/img/funcion_de_un_SE.png';

import { useDataSelectStore, useUIStore,useCatalogoStore } from '../hooks';
export const SEyCAE = ({ aspirante, idAspirante,actualizarSeccion }) => {

    const [openModal, setOpenModal] = useState(false);
    const { nombrePuesto, fotoAspirante, claseFoto } =
                                              usePuestoFotoAspirante(aspirante);
    const { startLoadingProcesos, listaProcesos} = useCatalogoStore();
    const [tipoAspirante, setTipoAspirante] = useState('')
    const [descripcionProceso, setDescripcionProceso] = useState('')
    const [funcionesAspirante, setFuncionesAspirante] = useState([])
    const { datosProcesos } = useDataSelectStore();

    useEffect(() => {
      console.info("info datosProcesos ",datosProcesos);
        if (aspirante.idPuesto !== 1) {
            setTipoAspirante('CAE');
            setFuncionesAspirante(funcionDeUnCAE);
        } else {
            setTipoAspirante('SE');
            setFuncionesAspirante(funcionDeUnSE);
        }
        console.info("info aspirante por url",aspirante);
        if (idAspirante > 0) {
          obtenerDescripcionProceso(aspirante.idProcesosElectoral)
          actualizarSeccion(aspirante.seccionCae !== "" ? aspirante.seccionCae :
            aspirante.seccionSe);
        }
    }, [aspirante.id])

    const handelOpenModal = () => {
        setOpenModal(true);
    }

    const obtenerDescripcionProceso = (procesoId) =>{
      console.log("entre al obtenerDescripcionProceso",procesoId);
      listaProcesos.map((item, index) => (
        item.idProcesoElectoral === procesoId ?
          setDescripcionProceso(item.descripcionDetalle) : ""
      ));
      console.log("la descripcionProceso",descripcionProceso);
    }

    return (
        <>
            <Card className={"contenidoSEyCAE"}>
                <Image src={fotoAspirante}
                    preview={false}
                    id={idAspirante === 'unico' ? 'imgFotoSEyCAEUnico' : 'imgFotoSEyCAE'}
                    className={claseFoto} />

                <Row className="infoSEyCAEDatos">
                    <Col xs={24} sm={24} md={24} lg={14} className="nombreSEyCAE">
                        <p>Nombre:</p>
                        <label>
                          <b>{`${aspirante.apellidoPaterno} ${aspirante.apellidoMaterno} ${aspirante.nombre}`}</b>
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} className="fechaInicioSEyCAE">
                        <p>Puesto:</p>
                        <label><b>{nombrePuesto}</b></label>
                    </Col>
                </Row>

                <Row className="infoSEyCAEDatos">
                    <Col xs={24} sm={24} md={24} lg={6} className="folioSEyCAE">
                          <p>Folio:</p>
                          <label><b>{aspirante.folio}</b></label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18} className="fechaInicioSEyCAE">
                        <p>Vigencia:</p>
                        <label><b>{`${aspirante.fechaFinVigencia}`}</b></label>
                    </Col>
                </Row>

               <Row className="infoSEyCAEDatos">
                    <Col xs={24} sm={24} md={24} lg={24} className="infoDescripProceso">
                          <p>Proceso Electoral: <b>{
                            datosProcesos.descProceso.length === 0 ?
                            descripcionProceso
                            : datosProcesos.descProceso
                          }</b></p>
                    </Col>
                </Row>
            </Card>

            <ModalInfo tipoAspirante={tipoAspirante}
                      funcionesAspirante={funcionesAspirante}
                      isOpenModal={openModal}
                      openModal={setOpenModal} />
        </>
    );

}
export default SEyCAE;
