import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    onDataReadQR, onSelectEstado, onSelectProceso, onSelectSeccion,
    onSelectIdParticipacion, onSelectIdAspirante, onSetDataAspirante,
     onCleanDataSelect,onFechasPeriodos
} from "../store/slices/dataSelectSlice"

export const useDataSelectStore = () => {

    const { datosProcesos,fechasPeriodo } = useSelector(state => state.dataSelect);

    const startLoadingFechas = async (isVerified) => {
        console.log('startLoadingProcesos',isVerified);
        //if (isVerified) {
            let url = process.env.REACT_APP_API_URL + '/consultaCatalogos';
            try {
                const data = await axios.post(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const { fechas } = data.data;
                dispatch(onFechasPeriodos(fechas))
            } catch (error) {
                console.log({ error });
            }
        //}
    }

    const dispatch = useDispatch();

    const startSelectProceso = (procesoSelec) => {
        console.log("startSelectProceso",procesoSelec);
        dispatch(onSelectProceso(procesoSelec));
    }
    const startSelectEstado = (idEstado) => {
        console.log("startSelectEstado",idEstado);
        dispatch(onSelectEstado(idEstado));
    }
    const startSelectSeccion = (seccion) => {
        console.log("startSelectSeccion",seccion);
        dispatch(onSelectSeccion(seccion));
    }

    const startSelectIdAspirante = (idAspirante) => {
        console.log("startSelectIdAspirante",idAspirante);
        dispatch(onSelectIdAspirante(idAspirante));
    }

    const startSelectIdParticipacion = (idParticipacion) => {
        console.log("startSelectIdParticipacion",idParticipacion);
        dispatch(onSelectIdParticipacion(idParticipacion));
    }

    const setDataAspirante = (data) => {
        console.log("setDataAspirante",data);
        dispatch(onSetDataAspirante(data))
    }

    const startCleanDataSelect = () => {
        dispatch(onCleanDataSelect());
    }

    const startDataReadQR = ({ dataQR = {} }) => {
        dispatch(onDataReadQR(dataQR))

    }
    return {
        //Propiedades
        datosProcesos,
        fechasPeriodo,
        //Metodos
        startSelectIdParticipacion,
        startSelectIdAspirante,
        startSelectProceso,
        startSelectEstado,
        startSelectSeccion,
        setDataAspirante,
        startDataReadQR,
        startCleanDataSelect
    }
}
