/*react */
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

/*antd */
import "antd";
/*Fontawesome*/
import "font-awesome/css/font-awesome.css";

/*Componentes */
//import * as etiquetas from './ApplicationResources';
import "../assets/css/App.css";
import App from './App';
import { descifrarCadena } from "../helpers/decifrarCadena";
import { useValidaEstructura} from "../hooks/useValidaEstructura";
import { Navigate } from "../router/Navigate";



export const LayoutApp = () => {
    console.log("seguimiento", process.env.REACT_APP_ID_SEGUIMIENTO);
    console.log("medicion", process.env.REACT_APP_ID_MEDICION);
    ReactGA.initialize(process.env.REACT_APP_ID_SEGUIMIENTO); //primera carga
    ReactGA4.initialize(process.env.REACT_APP_ID_MEDICION); //primera carga
    ReactGA.pageview(window.location.pathname + window.location.search); //numero de visitas
    return (
        <Navigate/>
    );
}

export const LoginLayaout = () => {
    return (
        <App cargando={false} />
    );
}

export const LoginApp = () => {

    const { isDecoded, jsonAspirante} = descifrarCadena();
    const {result} = useValidaEstructura(jsonAspirante)

    const defineAccess = () => {
        if (isDecoded) {
            if (result) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    if (defineAccess()) {
        return (
            <App cargando={false} />
        );
    }
    else {
        return (<App cargando={false} />);
    }

}

export default LayoutApp;
