
import { ConfigProvider, Col, Row } from 'antd';
import esES from 'antd/es/locale/es_ES';

import { useEffect } from "react";
import Layout, {  Footer, Header } from "antd/es/layout/layout"
/*Componentes */
import globalVars from '../utils/globalVars';
import { BuscaSEyCAE } from '../pages';
import FinSeYCae from './FinSeyCae';
import { InformacionAspirante } from '../pages';
import { useDataSelectStore, useUIStore } from '../hooks';
import mainLogo from "../assets/img/Grupo_5215.svg";
import footerLogo from '../assets/img/ine.png'
import { useCatalogoStore} from "../hooks";

const Template = ({ isVerified }) => {

  const { datosProcesos,fechasPeriodo } = useDataSelectStore();
  const {startLoadingProcesos,fechasProceso } = useCatalogoStore();
  const { isLoading } = useUIStore()

  const { idProceso,
        idDetalleProceso,
        idParticipacion,
        idAreaResponsabilidad,
        idAspirante } = datosProcesos;

    useEffect(() => {
      startLoadingProcesos(true)
    }, [])

    const validarEnteros = (valor) => {
        let valorCorrecto = undefined;
        let regex = /^[0-9]+$/;
        if (valor !== undefined && valor !== null) {
            valor = valor.trim();
            let valorEntero = parseInt(valor);
            if (regex.test(valorEntero)) {
                valorCorrecto = valorEntero
            }
        }
        return valorCorrecto;
    }

    let deshabilidarEncuesta = true;

    if (globalVars.uiText.idProceso !== undefined && globalVars.uiText.idProceso !== 0 && globalVars.uiText.idProceso !== '0' &&
        globalVars.uiText.idDetalle !== undefined && globalVars.uiText.idDetalle !== 0 && globalVars.uiText.idDetalle !== '0' &&
        globalVars.uiText.idParticipacion !== undefined && globalVars.uiText.idParticipacion !== 0 && globalVars.uiText.idParticipacion !== '0') {
        deshabilidarEncuesta = false;
    }

    if (deshabilidarEncuesta === false /*&& this.state.radioEncuesta !== undefined && this.state.radioEncuesta === 1*/) {
        deshabilidarEncuesta = true;
    }

    return (
        <ConfigProvider locale={esES}>
        <Header className={fechasProceso!== null && fechasProceso.length > 0 && fechasProceso[0].mostrarPantallaFin == false ? "mostrar" : "ocultar"}>
            <Row>
              <Col xs={24} sm={24} md={2} lg={2}>
                <img id="herader-img" src={mainLogo}
                      alt='heder-img' />
              </Col>
              <Col xs={24} sm={24} md={22} lg={22}>
                <p id="titulo-header">Conoce a tu SE y CAE</p>
              </Col>
            </Row>
        </Header>
            {
                isVerified && (fechasProceso!== null && fechasProceso.length > 0 && fechasProceso[0].mostrarPantallaFin == false)
                    ?
                    idProceso !== '0' && idDetalleProceso !== '0' && idParticipacion !== '0' &&
                        (idAspirante !== '0' || idAreaResponsabilidad !== '0')
                        && idProceso !== 0 && idDetalleProceso !== 0
                        && idParticipacion !== 0 &&
                        (idAspirante !== 0 || idAreaResponsabilidad !== 0)
                        ?
                        <InformacionAspirante
                            cargando={true}
                            datosProcesos={datosProcesos}
                            isVerified={isVerified}
                        />
                        :
                        <BuscaSEyCAE isVerified={isVerified} key="buscaSECae" />

                    :
                    <FinSeYCae/>
            }

            <Footer className={fechasProceso!== null && fechasProceso.length > 0 && fechasProceso[0].mostrarPantallaFin == false ? "mostrar footer" : "ocultar footer"}>
                <Row>
                    <Col xs={24} sm={24} md={2} lg={2}>
                        <img className='logo-footer' src={footerLogo} alt="logo ine" />
                    </Col>
                    <Col xs={24} sm={24} md={20} lg={20} className="text-footer">
                        <h5 >© Derechos reservados, Instituto Nacional Electoral, México |
                            Unidad Técnica de Servicios de Informática</h5>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} className="text-footer">
                        <h5 >Versión del Sistema 4.0 </h5>                        
                    </Col>
                </Row>
            </Footer>
        </ConfigProvider >
    );
}
export default Template;
